.slide {
  @apply mx-2 relative overflow-hidden rounded-lg flex-shrink-0;
  width: calc(100vw - 40px);
  padding-top: 100%;

  // @screen 2sm {
  //   @apply mx-4;
  //   height: 200px;
  //   width: 400px;
  // }

  @screen 2sm {
    padding-top: 0;
    height: 320px;
    width: 680px;
  }

  @screen xl {
    @apply mx-3;
    width: 650px;
  }

  @screen 2xl {
    height: 400px;
    width: 800px;
  }
}
