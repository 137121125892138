.field:not(.field--error) {
  @apply border-sbtviolet-200/90;
  caret-color: theme('colors.sbtviolet.550');
}

.field:not(.field--error, :disabled, :read-only):hover {
  @apply border-sbtviolet-550;
}

.field:not(.field--error, :disabled, :read-only):focus {
  @apply outline-none border-sbtviolet-550;
}

.field::-webkit-outer-spin-button,
.field::-webkit-inner-spin-button {
  @apply m-0;
  -webkit-appearance: none;
}

.field[type='number'] {
  -moz-appearance: none;
}

.field:focus {
  @apply outline-none;
}

.field::placeholder {
  @apply text-black/40;
}

.field:disabled,
.field:read-only {
  @apply cursor-not-allowed select-none bg-sbtviolet-100/50;
}

.field--error {
  @apply bg-sbtred-100 border-sbtred-400;
}

@supports (-webkit-touch-callout: none) {
  .field {
    @apply text-base;
  }
}
