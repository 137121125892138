.group {
  grid-template-areas: "fansi customer_service"
    "have_questions have_questions"
    "follow_us follow_us"
    "brandbuddies_group brandbuddies_group";
}

@screen sm {
  .group{
    grid-template-areas: "fansi customer_service"
      "have_questions follow_us"
      "brandbuddies_group brandbuddies_group";
  }
}
