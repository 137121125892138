.featured-products {
  @apply mt-12 py-8 w-full;
  background: #fee4ce;
  h2 {
    color: black;
  }

  @screen md {
    @apply mt-20 py-12;
  }

  @screen xl {
    @apply py-16;
  }
}
