.slider {
  @apply relative;

  &::before {
    @apply hidden;
    content: '{"dragFree": true }';

    @screen xl {
      content: '{"draggable": false, "speed": 10, "align": "start" }';
    }
  }
}