.carousel {
  @apply flex flex-col relative w-full pl-4 overflow-hidden;

  @screen 2sm {
    @apply pl-8;
  }

  @screen xl {
    @apply pl-0;
  }

  &::before {
    @apply hidden;
    content: '{"draggable": true }';

    @screen lg {
      content: '{"draggable": false }';
    }
  }
}