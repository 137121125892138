.product-name {
  @apply z-20 overflow-hidden leading-none break-words;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.product-cover {
  @apply relative overflow-hidden;
}

.product-favourite {
  @apply absolute z-50 right-2 p-2 bg-white border-[1px] rounded-b-lg  border-white border-solid;
}

.product-cover--flex {
  height: 220px;
}

.product-cover--grid {
  height: 175px;
}

@screen 2sm {
  .product-cover--grid {
    height: 225px;
  }
}

@screen sm {
  .product-cover--grid {
    height: 195px;
  }
}

@screen md {
  .product-cover--flex,
  .product-cover--grid {
    height: 280px;
  }
}

@screen 2lg {
  .product-cover--grid {
    height: 225px;
  }
}

@screen lg {
  .product-cover--grid {
    height: 280px;
  }
}

@screen xl {
  .product-name {
    -webkit-line-clamp: 2;
  }

  .product-cover--flex {
    height: 325px;
  }
}

@media (min-width: 1320px) {
  .product-cover--grid {
    height: 250px;
  }
}

@media (min-width: 1480px) {
  .product-cover--grid {
    height: 300px;
  }
}
