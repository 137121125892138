.creator-avatar {
  @apply relative w-full overflow-hidden rounded-lg;

  &--flex {
    height: 275px;

    @screen xl {
      height: 300px;
    }
  }

  &--grid {
    height: 250px;

    @media(min-width: 360px) {
      height: 225px;
    }

    @screen 2sm {
      height: 275px;
    }

    @screen sm {
      height: 225px;
    }

    @screen md {
      height: 280px;
    }

    @screen lg {
      height: 325px;
    }

    @media(min-width: 1320px) {
      height: 280px;
    }

    @media(min-width: 1480px) {
      height: 300px;
    }
  }
}